import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    spreadsheet: {
        name: "Your Company",
        about: "Awesome Project",
        contact: "867-5309",
        "map url": "https://maps.app.goo.gl/ucAgnEixjjZNkxgP9",
        "social media": "https://www.facebook.com/walkum.records",
    },
};

const sitesDemoSlice = createSlice({
    name: 'sitesDemo',
    initialState,
    reducers: {
        updateCell(state, action) {
            const { key, value } = action.payload;
            if (state.spreadsheet.hasOwnProperty(key)) {
                state.spreadsheet[key] = value; // Update the second column of the specified row
            }
        },
    },
});

export const {
    updateCell,
} = sitesDemoSlice.actions;

export default sitesDemoSlice.reducer;
