import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "./src/state/store";

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            {element}
        </PersistGate>
    </Provider>
);
